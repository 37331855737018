import { Component, OnInit } from '@angular/core';
import { MatDatepicker, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Location } from '@angular/common'
import { DatabaseService } from '../_services/DatabaseService';
import { DialogComponent } from '../dialog/dialog.component';
import { SessionStorage } from '../_services/SessionService';
import { MastetDateFilterModelComponent } from '../mastet-date-filter-model/mastet-date-filter-model.component';
@Component({
  selector: 'app-client-ledger-list',
  templateUrl: './client-ledger-list.component.html',
  styleUrls: ['./client-ledger-list.component.scss']
})
export class ClientLedgerListComponent implements OnInit {
  loading_list = true;
  wallet_history_type: any = 'ledger'
  ledgerData: any = [];
  sr_no: number;
  tabType: any = 'transaction';
  balance: any;
  page_limit: any = 100;
  constructor(public service: DatabaseService, public toastr: DialogComponent, public dialog: MatDialog, public ActivatedRoute: ActivatedRoute, public route: Router, public session: SessionStorage, public alert: DialogComponent, public alrt: MatDialog) {
    this.filter.status = 'ledger';
    this.getBalance();
    this.getLedger('ledger','');

  }

  ngOnInit() {
  }

  refresh(type) {
    this.filter.date_from = '';  
    this.filter.date_to = ''; 
    this.filter.status = type ;
    if (this.start < 0) {
      this.start = 0;
    }
    
    this.getLedger(type,'');
  }

  clearFilter() {
    if (this.start < 0) {
      this.start = 0;
    }
  }

  pervious(type) {
    this.start = this.start - this.page_limit;
    this.filter.transaction_type = type;
    this.getLedger(type,'');
  }
  date_format(): void {
    this.filter.date_created = moment(this.filter.date_created).format('YYYY-MM-DD');
    this.getLedger('ledger','');
  }
  nextPage(type) {
    this.start = this.start + this.page_limit;
    this.filter.transaction_type = type;
    this.getLedger(type,'');
  }

  pageCount: any;
  pagenumber: any = '';
  start: any = 0;
  total_page: any;
  filter: any = {}
  statusmsg: any = '';
  datanotfound: boolean = false;

  getLedger(type,start_no) {

    if(start_no){
      this.start = 0;
    }
    
    this.loading_list = true;
    this.filter.transaction_type = type;
    this.loading_list = true;
    this.filter.date_created = this.filter.date_created ? this.service.pickerFormat(this.filter.date_created) : '';
    if (this.filter.date_from) {
      this.filter.date_from = moment(this.filter.date_from).format('YYYY-MM-DD');
    }
    if (this.filter.date_to) {
      this.filter.date_to = moment(this.filter.date_to).format('YYYY-MM-DD');

    }

    if (this.pagenumber > this.total_page) {
      this.pagenumber = this.total_page;
      this.start = this.pageCount - this.page_limit;
    }
    if (this.start < 0) {
      this.start = 0;
    }
    this.filter.start = this.start;
    this.filter.pagelimit = this.page_limit;
    this.filter.date_from = this.filter.date_from;
    this.filter.date_to = this.filter.date_to;
    this.filter.date_created = this.filter.date_created;

    this.service.post_rqst({ 'filter': this.filter }, 'offer/transaction_history').subscribe((resp) => {

      if (resp['statusCode'] == 200) {

        this.loading_list = false;
        this.ledgerData = resp['client_ledger'];
        console.log(this.ledgerData);
        this.pageCount = resp['count'];
        this.getBalance();

        if (this.pagenumber > this.total_page) {
          this.pagenumber = this.total_page;
          this.start = this.pageCount - this.page_limit;
        }
        else {
          this.pagenumber = Math.ceil(this.start / this.page_limit) + 1;
        }
        this.total_page = Math.ceil(this.pageCount / this.page_limit);
        this.sr_no = this.pagenumber - 1;
        this.sr_no = this.sr_no * this.page_limit;
        setTimeout(() => {
        }, 700)
      }
      else if (resp['statusCode'] == 400) {
        this.loading_list = false;
        this.statusmsg = resp['error'];
        this.toastr.error(this.statusmsg);
      }
    })

  }

  credit_amt: any = 0;
  total_bal: any = 0;
  debit_amt: any = 0;
  getBalance() {
    this.loading_list = true;

    this.service.post_rqst({ 'filter': '' }, 'offer/get_balance').subscribe((resp) => {

      if (resp['statusCode'] == 200) {
        this.balance = resp['result'];
        this.total_bal = parseInt(this.balance.total_balance);
        this.credit_amt = parseInt(this.balance.total_credit);
        this.debit_amt = parseInt(this.balance.total_debit);
        this.loading_list = false;
      }
      else if (resp['statusCode'] == 400) {
        this.toastr.error(resp['error']);
      }
    })
  }


  openDatePicker(picker: MatDatepicker<Date>) {
    picker.open();
  }

  openDatepicker(): void {
    const dialogRef = this.alrt.open(MastetDateFilterModelComponent, {
      width: '500px',
      data: {
        from: this.filter.date_from,
        to: this.filter.date_to,
        status: this.filter.status,
        transaction_type: this.filter.transaction_type,


      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        console.log(result);
        this.filter.date_from = result.from;
        this.filter.date_to = result.to;
        this.filter.transaction_type = result.transaction_type;
        this.filter.status = result.status;
        this.getLedger(this.filter.status,'');
      });
  }


  exportClientLedger()
  {
      this.filter.mode = 1;
      this.service.post_rqst(  {'filter': this.filter , 'login':this.service.datauser}, 'offer/transaction_history_excel')
      .subscribe( d => {
          document.location.href = this.service.myurl+'/app/uploads/exports/'+ d.file_name;
          console.log(d);
      });
  }

}
