import { Component,Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DatabaseService} from '../../_services/DatabaseService';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogComponent} from '../../dialog/dialog.component';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
})
export class ChangeStatusComponent implements OnInit {
  
  data: any = {};
  loading_list:any = false;
  mode:any;
  savingData = false;

  gift_id;
  offer:any = {};
  from:any;
  otp:any;
  
  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router,  public dialog: DialogComponent,
    @Inject(MAT_DIALOG_DATA) public model_data: any, public dialogRef: MatDialogRef<ChangeStatusComponent>) {
      //console.log(model_data);
      
      this.data.id = model_data.id; 
      this.data.gift_status = model_data.status; 
      this.from = model_data.from; 
      if(this.from=='adminOtp'){
        this.otpverify();
      }

      
    }
    ngOnInit() {
      this.route.params.subscribe(params => {
        this.gift_id = params['gift_id'];
        this.offer.gift_status = this.data.gift_status; 
//console.log( this.offer );

      });
    }
   
    addoffer(form:any)
    {
      this.savingData = true;
      this.db.post_rqst( { 'status' : this.offer ,'id': this.data.id }, 'offer/redeemStatus')
      .subscribe( result => {
        this.savingData = false;
        this.dialog.success( 'Status successfully Change');
        console.log( "inside result" + result);
        this.dialogRef.close(true);
        //console.log( d );
      });
      console.log( "outside result" );
      this.dialogRef.close(true);

    }

    onNoClick(): void{
    this.dialogRef.close();
    }

    numeric_Number(event: any) {
      const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
          event.preventDefault();
      }
  }

  otpmatched(otp){
   
    if(this.otp==otp && otp.length==4){
    this.dialog.success( 'OTP Verified Successfully');

    }
  }


  otpverify()
  {
    this.db.get_rqst({}, 'app_master/generateOtp')
    .subscribe( result => {
      this.otp=result.otp
      this.dialog.success( 'Otp Sent successfully ');
    
    });
   
  }

   verified(otp){
    if(this.otp!=otp && otp.length==4){
      this.dialog.error( 'Wrong OTP Value');
      return;
      }
    this.dialogRef.close(true);

   }
  }
  