import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { DatabaseService } from 'src/app/_services/DatabaseService';

@Component({
  selector: 'app-bank-detail-modal',
  templateUrl: './bank-detail-modal.component.html',
  styleUrls: ['./bank-detail-modal.component.scss']
})
export class BankDetailModalComponent implements OnInit {
  karigarform: any = {};
  savingData:boolean=false;
  docId:any;
  scheme_id:any;

  offer_imgurl:string='';
  karigar_id:any;


  constructor(public db:DatabaseService,@Inject(MAT_DIALOG_DATA)  public params: any,  public dialog: DialogComponent, public dialogs: MatDialogRef<BankDetailModalComponent>) { 
    console.log(params);
    this.offer_imgurl = this.db.offer_imgurl;

    if(params.from =='billedit'){
    this.scheme_id=params.id;
    this.karigar_id=params.karigar_id;
    this.docId=params.id;
    this.karigarform.accessible_amount=params.accessible_amount
    this.karigarform.gst_bill_amount=params.gst_bill_amount
    this.karigarform.bill_image=params.bill_image
    this.karigarform.bill_date=params.bill_date
    this.karigarform.offer_id=params.offer_id
    console.log(this.karigarform.offer_id);
    
    }
    this.karigarform.redeem_id = params.id;
    this.karigarform.updated_by = 'Admin'
    this.karigarform.bank_name = params.bank_name;
    this.karigarform.bank_account_number = params.bank_account_number;
    this.karigarform.bank_ifsc_code = params.bank_ifsc_code;
    this.karigarform.account_holder_name = params.account_holder_name;
    this.karigarform.type=params.type;
    console.log(this.karigarform.type);


    
  }

  ngOnInit() {
    this.getschemeLiat();

  }

  submit(form:any)
  {
    this.savingData = true;
      this.db.insert_rqst( {'bank_name' : this.karigarform.bank_name,'redeem_id' : this.karigarform.redeem_id,'bank_account_number' : this.karigarform.bank_account_number,'bank_ifsc_code' : this.karigarform.bank_ifsc_code,'account_holder_name' : this.karigarform.account_holder_name }, 'app_karigar/update_bank_details')
      .subscribe( d => {
          this.savingData = false;
          // this.loading_list = false;
          console.log( d );
          this.dialog.success('Bank detail successfully updated');
          this.dialogs.close(true);
      });
  }


  editBillsubmit()
  {

    if(this.params.from=='billedit'){
      this.savingData = true;
      this.db.insert_rqst( {'created_by':this.db.datauser.id,'karigar_id':this.karigar_id,'accessible_amount' : this.karigarform.accessible_amount,'scheme_id' : this.scheme_id,'gst_bill_amount' : this.karigarform.gst_bill_amount,'bill_image' : this.karigarform.bill_image}, 'karigar/update_bill_amount')
      .subscribe( d => {
          this.savingData = false;
          // this.loading_list = false;
          console.log( d );
          this.dialog.success('Bill Detail successfully updated');
          this.dialogs.close(true);
      });

    }
    else{


      if(!this.karigarform.bill_image){
        this.dialog.error('Bill Image Is required');
        return;


      }


      this.savingData = true;
      this.karigarform.bill_date = this.karigarform.bill_date  ? this.db.pickerFormat(this.karigarform.bill_date) : '';
      this.karigarform.karigar_id=this.params.karigar_id;
      // this.karigarform.created_by = this.db.datauser.id;
      this.db.insert_rqst( {'data':this.karigarform}, 'karigar/addScheme')
      .subscribe( d => {
          this.savingData = false;
          // this.loading_list = false;
          console.log( d );
          this.dialog.success('Bill Add successfully');
          this.dialogs.close(true);
      });

    }
    
  }

  capsAdd(ifsc){
    this.karigarform.ifsc_code = ifsc.toUpperCase();
  }




  onUploadbillChange(evt: any) {
    const file = evt.target.files[0];
    console.log(file);
    if (file) {
        const reader = new FileReader();
        reader.onload = this.handleReaderbillLoaded.bind(this);
        reader.readAsBinaryString(file);
        this.docId = '';
    }
}
handleReaderbillLoaded(e) {
    this.karigarform.bill_image = 'data:image/png;base64,' + btoa(e.target.result) ;
    console.log( this.karigarform.bill_image );
}


numeric_Number(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
  }
}


gstRate: number = 100;
gstremoveamount:number;

getValue(value){
  console.log(value)

  this.gstremoveamount=(value * this.gstRate) / 118;
 console.log(this.gstremoveamount);
  this.karigarform.accessible_amount =  Math.floor(this.gstremoveamount)


}
schemeList: any = [];
getschemeLiat(){
  // this.loading_list = false;
  this.db.post_rqst({'type' : this.karigarform.type,}, 'karigar/schemeList')
  .subscribe(d => {  
      // this.loading_list = true;  
      this.schemeList = d.offer;
      console.log(this.schemeList);
      
  });
}

}
