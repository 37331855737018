import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {DatabaseService} from '../../_services/DatabaseService';
import {DialogComponent} from '../../dialog/dialog.component';
import {ChangeStatusComponent} from '../../gift-gallery/change-status/change-status.component';
import {MatDialog, MatDatepicker} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionStorage} from '../../_services/SessionService';
import { ShippedDetailModelComponent } from '../shipped-detail-model/shipped-detail-model.component';
import { BankDetailModalComponent } from 'src/app/bank-detail-modal/bank-detail-modal.component';
// import { MastetDateFilterModelComponent } from 'src/app/mastet-date-filter-model/mastet-date-filter-model.component';

@Component({
    selector: 'app-redeem-request-list',
    templateUrl: './redeem-request-list.component.html',
})
export class RedeemRequestListComponent implements OnInit {
    
    loading_list = false;
    reedam: any = [];
    locations: any = [];
    total_reedam = 0;
    
    reedam_all:any =0;
    reedam_pending:any =0;
    reedam_approved:any =0;
    reedam_reject:any =0;
    reedam_verified:any=0;

    Inprocess:any =0;
    Failed:any =0;
    Success:any =0;

    
    last_page: number ;
    current_page = 1;
    search: any = '';
    filter:any = {};
    filtering : any = false;
    active_pending_tab:any;
    redeem_under_process_count:any =0;
    sub_active_tab:any ='0';

    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public ses: SessionStorage,public dialog: DialogComponent, public alrt:MatDialog ) {
        // this.userData = JSON.parse(localStorage.getItem('st_user'));

    }
    
    ngOnInit() {
        this.route.params
        .subscribe((resp)=>
        {
            console.log(resp);
            this.active_pending_tab = resp.pending;
        })
        
        if(this.active_pending_tab == 'pending')
        {
            this.filter.gift_status = 'Pending';
        }
        else
        {
            this.filter.gift_status = '';
        }
        
        this.getReedamList('');
        this.AssignSaleUser();
        
    }
    
    openDatePicker(picker : MatDatepicker<Date>)
    {
        picker.open();
    }
    redirect_previous() {
        this.current_page--;
        this.getReedamList('');
    }
    redirect_next() {
        if (this.current_page < this.last_page) { this.current_page++; }
        else { this.current_page = 1; }
        this.getReedamList('');
    }
    
    current1()
    {
        this.current_page = 1;
        this.getReedamList('');
    }
    last1()
    {
        this.current_page = this.last_page;
        this.getReedamList('');
    }
    getReedamList(action:any) 
    {
        this.loading_list = true;
        this.filter.date = this.filter.date  ? this.db.pickerFormat(this.filter.date) : '';
        this.filter.payout_transaction_date = this.filter.payout_transaction_date  ? this.db.pickerFormat(this.filter.payout_transaction_date) : '';
        this.filter.start_date = this.filter.start_date  ? this.db.pickerFormat(this.filter.start_date) : '';
        this.filter.end_date = this.filter.end_date  ? this.db.pickerFormat(this.filter.end_date) : '';
        this.filter.payout_transaction_refund_date = this.filter.payout_transaction_refund_date  ? this.db.pickerFormat(this.filter.payout_transaction_refund_date) : '';
        if(this.filter.gift_status == 'Approved'){
            this.filter.sub_status = this.sub_active_tab;
        }else{
         this.filter.sub_status = '';
        }
        if( this.filter.date )this.filtering = true;
        if( this.filter.payout_transaction_date )this.filtering = true;

        this.filter.mode = 0;
        
        if(action=='refresh')
        { 
            let status = this.filter.gift_status;
            this.filter={};
            this.filter.gift_status= status;
            // this.current_page= 1;
        }
        
        this.db.post_rqst(  {  'filter': this.filter , 'login':this.db.datauser}, 'offer/redeemList?page=' + this.current_page )
        .subscribe( d => {
            console.log(d);
            
            this.current_page = d.redeem.current_page;
            this.last_page = d.redeem.last_page;
            this.total_reedam =d.redeem.total;
            this.reedam = d.redeem.data;
            this.reedam_all = d.redeem_all;
            this.reedam_pending = d.redeem_pending;
            this.reedam_approved = d.redeem_approved;
            this.reedam_reject = d.redeem_reject;
            this.redeem_under_process_count = d.redeem_failed;

            this.Failed = d.redeem_approved_failed;
            this.Success = d.redeem_approved_success;
            this.Inprocess = d.redeem_approved_inprocess;


            this.loading_list = false;
        });
    }
    
    exportRedeemList()
    {
        this.filter.mode = 1;
        this.db.post_rqst(  {'filter': this.filter , 'login':this.db.datauser}, 'offer/exportRedeemList')
        .subscribe( d => {
            this.loading_list = false;
            document.location.href = this.db.myurl+'/app/uploads/exports/redeemlist.csv';
            console.log(d);
        });
    }
    sales_users:any=[];
    AssignSaleUser()
    {
        // this.loading_list = true;
        this.db.get_rqst(  '', 'karigar/sales_users')
        .subscribe(d => {
            console.log(d);
            // this.loading_list = false;
            this.sales_users = d.sales_users;
            console.log(this.sales_users);
        });
    }
    deleteOffer(id) {
        // this.dialog.delete('Karigar').then((result) => {
        //   if(result) {
        // let id;
        this.db.post_rqst({'id': id}, 'reedam/remove')
        .subscribe(d => {
            console.log(d);
            this.getReedamList('');
        });
    }
    //   });
    // } 
    
    changeStatus(i,id,status)
    {
        console.log(status);
        
        const dialogRef = this.alrt.open(ChangeStatusComponent,{
            width: '500px',
            // height:'500px',
            
            data: {
                'id' : id,
                'status' : status,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if( result ){
                this.getReedamList('');
            }
        });
        
    }
    
    
    shippedModel(i){
        
        const dialogRef = this.alrt.open(ShippedDetailModelComponent,{
            width: '500px',
            
            data: {
                id:  this.reedam[i].id ,
                karigar_id:this.reedam[i].karigar_id,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if( result ){
                this.giftStatus(i);
            }else{
                this.getReedamList('');
            }
        });
        
        
    }
    
    karigarsSatus(i) {
        
        // if(this.reedam[i].receive_status == 'Shipped')
        if(this.reedam[i].receive_status == 'Shipped')

        {
            this.shippedModel(i);
            return;
        }
        
        this.giftStatus(i);
        
    }

    transform(value: number): number {
        return Number(value);
      }

    giftStatus(i){
        console.log('ship');

        if(this.reedam[i].receive_status == 'Transferred')
        {
        console.log('transfer');
        console.log(this.reedam[i].actual_points);
        console.log(this.ses.users.payout_per_transaction_limit);
        
            if(this.reedam[i].actual_points > this.ses.users.payout_per_transaction_limit){
        console.log('transfer2');
                this.loading_list = false;
                    this.dialog.error('Maximum per transaction allowed amount ₹ '+this.ses.users.payout_per_transaction_limit);
                    this.getReedamList('');
                    return;

                }

            const dialogRef = this.alrt.open(ChangeStatusComponent,{
                width: '500px',
                data: {
                    'from' : 'adminOtp',
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if( result ){
                    this.dialog.paymentAlert(this.reedam[i].actual_points).then((result) => {
                        this.loading_list = true;
                        
                        if(result) {
                            this.db.post_rqst({ 'receive_status' : this.reedam[i].receive_status, 'id' : this.reedam[i].id }, 'offer/redeemReceiveStatus')
                            .subscribe(d => {
                                
                                if(d['status'] == 'SUCCESS'){
                                this.loading_list = false;
                                    this.dialog.success('Fund has been transferred successfully ');
                                    this.getReedamList('');
                                    return;
        
                                }
                                else if(d['status'] =='FAILED'){
                                    this.loading_list = false;
                                     this.dialog.error('Something Went Wrong!');
                                    this.getReedamList('');
                                     return;
                                 }
                                else if(d['status'] == 'GIFTALLREADYTRANSFERED'){
                                   this.loading_list = false;
                                    this.dialog.error('Already Transfered ');
                                    this.getReedamList('');
                                    return;
                                }
                                else if(d['status'] == 'account_no_or_ifsc_code'){
                                    this.loading_list = false;
                                     this.dialog.error('Bank details are missing! ');
                                    this.getReedamList('');
                                     return;
                                 }
                                 else if(d['status'] == 'payment_already_transferred'){
                                    this.loading_list = false;
                                     this.dialog.error('Payment already transferred! ');
                                    this.getReedamList('');
                                     return;
                                 }
                                else{
                                   this.loading_list = false;
                                    this.dialog.alerts('Something Went Wrong!');
                                }
                                this.getReedamList('');
                            });
                        }
                        else{
                            
                            this.getReedamList('');
                        }
                    });  
                }
                else{
                    this.getReedamList('');
                }
            });


         
        }  
        else{
            
            this.db.post_rqst({ 'receive_status' : this.reedam[i].receive_status, 'id' : this.reedam[i].id }, 'offer/redeemReceiveStatus')
                .subscribe(d => {
                    this.dialog.success('Success!');
                    this.getReedamList('');
                });
        }      
    }
    
    
    
    // giftStatus(i){
    //     console.log('ship');
        
    //     this.db.post_rqst({ 'receive_status' : this.reedam[i].receive_status, 'id' : this.reedam[i].id }, 'offer/redeemReceiveStatus')
    //     .subscribe(d => {
    //         this.dialog.success('Success!');
    //         this.getReedamList('');
    //     });
        
    // }
    
    // openDatepicker(): void {
    //     const dialogRef = this.alrt.open(MastetDateFilterModelComponent, {
    //         width: '500px',
    //         data: {
    //             from:this.filter.date_from,
    //             to:this.filter.date_to
    //         }
    //     });
        
    //     dialogRef.afterClosed().subscribe(result => {
    //         this.filter.date_from = result.from;
    //         this.filter.date_to = result.to;
    //         this.getReedamList('');
    //     });
    // }
    

    updateBankDetails(id,account_holder_name,bank_name,account_no,ifsc_code): void {
        const dialogRef = this.alrt.open(BankDetailModalComponent,{
            width: '650px',
            data :
            {
                'id' : id,
                'account_holder_name' : account_holder_name,
                'bank_name' : bank_name,
                'bank_account_number' : account_no,
                'bank_ifsc_code' : ifsc_code,

            }
            
            
        });
        
        dialogRef.afterClosed().subscribe(result => {
            this.getReedamList(''); 
            
        });
    }
    
}
